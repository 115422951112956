import React from 'react';
import { useLocation } from 'react-router-dom';

// hook to check the app version on route change
export const useVersionCheck = () => {
	// check if a version update (refresh) needed on route change
	const location = useLocation();
	React.useLayoutEffect(() => {
		// if there is an update available and no state passed to route
		if (!location.state && window.localStorage.getItem('myanim-version-update-needed')) {
			window.localStorage.removeItem('myanim-version-update-needed'); // remove the storage object
			window.location.reload(); // refresh the browser
		}
	}, [location]);
};
