import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import {
	Route,
	Redirect,
	useHistory,
} from 'react-router-dom';

import { cognitoGroup } from '../lib/cognito/groups';
import { UserContext } from './UserContext';
import { useEventCallback } from '../lib/hooks/useEventCallback';

// const adminGroup = process.env.REACT_APP_AWS_COGNITO_GROUP_ADMIN;
// const animatorGroup = process.env.REACT_APP_AWS_COGNITO_GROUP_ANIMATOR;
// const superAdminGroup = process.env.REACT_APP_AWS_COGNITO_GROUP_SUPERADMIN;
// const managerGroup = process.env.REACT_APP_AWS_COGNITO_GROUP_MANAGER;

export const PrivateRoute = (props) => {
	const history = useHistory();
	const context = useContext(UserContext);
	const { updateCurrentUser } = context;
	const onListen = useEventCallback(updateCurrentUser);

	useEffect(() => {
		const unlisten = history.listen(() => {
			onListen();
		});
		return () => {
			unlisten();
		};
	}, [history, onListen]);

	// componentDidMount() {
	// 	const { history } = this.props;
	// 	const { context } = this;
	// 	this.unlisten = history.listen(() => {
	// 		context.updateCurrentUser();
	// 	});
	// }

	// componentWillUnmount() {
	// 	this.unlisten();
	// }

	const { component: Comp, role, ...rest } = props;
	// eslint-disable-next-line react/destructuring-assignment
	const userGroups = context.user?.signInUserSession?.accessToken?.payload['cognito:groups'];
	// eslint-disable-next-line react/destructuring-assignment
	const isAuthenticated = !!(context.user?.username);
	let hasPermission = false;
	hasPermission = userGroups?.includes(role);
	const isAllowed = hasPermission && isAuthenticated;
	const { isLoaded } = context;
	if (!isLoaded) return null;
	return (
		<Route
			{...rest}
			render={(_props) => {
				if (isAllowed) {
					return 	(<Comp {..._props} />);
				}
				if (isAuthenticated && !isAllowed && isLoaded) {
					if (userGroups?.includes(cognitoGroup.admin)) {
						return (<Redirect from="/" to="/admin" />);
					}
					if (userGroups?.includes(cognitoGroup.manager)) {
						return (<Redirect from="/" to="/manager" />);
					}
					return (<Redirect to={{ pathname: '/forbidden' }} />);
				}
				return (<Redirect to={{ pathname: '/login' }} />);
			}}
		/>
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.oneOfType([
		Route.propTypes.component,
		PropTypes.object,
	]).isRequired,
	role: PropTypes.string.isRequired,
};
