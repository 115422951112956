// eslint-disable-next-line
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faAngleRight,
	faArrowRight,
	faCalendarDay,
	faChalkboardTeacher,
	faChevronDown,
	faChevronRight,
	faChevronUp,
	faCommentDots,
	faEdit,
	faEye,
	faEyeSlash,
	faHeadset,
	faHome,
	faInfoCircle,
	faPowerOff,
	faQuestionCircle,
	faSearch,
	faSpinner,
	faStar,
	faTimes,
	faUndo,
	faUpload,
	faUser,
	faUserEdit,
	faUserFriends,
} from '@fortawesome/free-solid-svg-icons';

library.add(
	faAngleRight,
	faArrowRight,
	faCalendarDay,
	faChalkboardTeacher,
	faChevronDown,
	faChevronRight,
	faChevronUp,
	faCommentDots,
	faEdit,
	faEye,
	faEyeSlash,
	faHeadset,
	faHome,
	faInfoCircle,
	faPowerOff,
	faQuestionCircle,
	faSearch,
	faSpinner,
	faStar,
	faTimes,
	faUndo,
	faUpload,
	faUser,
	faUserEdit,
	faUserFriends,
);
